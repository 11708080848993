
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Step1 from "@/user-journey/components/Step1.vue";
import { getAuth } from "firebase/auth";
import { getUser } from "@/api/user.api";

export default defineComponent({
  name: "AccountSelection",
  components: {
    Step1,
  },
  async beforeRouteEnter(to, from, next) {
    const uuid = getAuth().currentUser?.uid;
    try {
      await getUser(uuid);
      await next("/dashboard");
    } catch (e) {
      next();
    }
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const role = computed(() => store.getters["UserModule/getUserRole"]);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const onChangeAccountType = (accountType) => {
      store.dispatch("UserModule/setUserRole", accountType);
    };

    const routeToWizard = async () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      await store.dispatch("UserModule/fetchAllIndustries");
      await store.dispatch("UserModule/fetchAllProjectStatuses");
      if (role.value === "expert") {
        await store.dispatch("ExpertModule/fetchSeniorityLevels");
        await router.push("/expert-wizard");
      } else {
        await store.dispatch("FounderModule/fetchBusinessModals");
        await store.dispatch("FounderModule/fetchCustomerGroups");
        await router.push("/founder-wizard");
      }
    };

    return {
      onChangeAccountType,
      routeToWizard,
      submitButton,
    };
  },
});
