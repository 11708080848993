
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-1",
  emits: ["on-change-account-type"],
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const userRole = computed(() => store.getters["UserModule/getUserRole"]);
    const accountType = ref(userRole.value);

    return { accountType };
  },
});
